import React from 'react'
import {
  PostListResponse,
} from '../../../utils/blog'
import butter from '../../../butter'

import OverviewItems from '../OverviewItems/OverviewItems'

export interface PressReleaseListProps {
  readonly press_release_data: PostListResponse;
}

const PAGE_SIZE = 3

export default function PressReleaseList({
  press_release_data,
}: PressReleaseListProps) {
  return (
    <OverviewItems
      item_data={press_release_data}
      type_items="press-release"
      page_size_items={PAGE_SIZE}
    />
  )
}

PressReleaseList.getInitialProps = async function () {
  const initialPosts = await butter.post.list({
    page: 1,
    page_size: PAGE_SIZE,
    exclude_body: true,
    category_slug: 'press-release',
  })

  return {
    press_release_data: initialPosts.data,
  }
}
