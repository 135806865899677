import React, { useContext } from 'react'
import {
  Box, Grid,
  Hidden,
  Typography,
} from '@material-ui/core'
import { classes } from './SummarySection.styles'
import { useOrderContext } from '../../../OrderContext'
import getStepUrl from '../../../getStepUrl'
import { useRouter } from 'next/router'
import { OrderStep } from '../../../../../pages/order/[...step]'
import { PremiseContext } from '../../../../PremiseTracker/PremiseContext'
import ProductDetails from './sections/ProductDetails'
import InstallationDetails from './sections/InstallationDetails'
import PersonalDetails from './sections/PersonalDetails'
import PaymentDetails from './sections/PaymentDetails'
import OTSDetails from './sections/OTSDetails'
import { formatInstallationAddress } from '../InstallationSection/InstallationSection'
import OrderError from '../OrderError/OrderError'
import OrderButton from '../Basket/OrderButton'
import { cx } from 'linaria'
import GiftIcon from './icons/GiftIcon'
import CountdownBanner from '../CountdownBanner/CountdownBanner'
import { ProductProps } from '../../../../Products/Product'
import {
  addToCartOptions,
  removeFromCart,
} from '../../../../Packages/Helpers'
import createCRMApiClient from '../../../../../api/CRMApi'
import {
  ButterCMSComponent, getButterComponentByType,
} from '../../../../../butter'

interface SummaryProps {
  summaryData: {
    components: ButterCMSComponent[];
  };
}
const SummarySection = ({ summaryData }: SummaryProps) => {
  const router = useRouter()
  const { premise } = useContext(PremiseContext)
  const isCommunity = premise?.type === 'Community'
  const client = createCRMApiClient()
  const {
    options, setOptions,
  } = useOrderContext()
  const {
    broadband, bundle, voice, tv, wier, vas, discount, timeslot, data, promo, paymentDetails,
    placeOrderError, appliedReferralCode, appliedProductId, countDownTimerStart, dcmsEligibility, cartInstanceIdentifier,
    oneTouchSwitching,
  } = options
  const switchStep = (step: OrderStep) => {
    return router.push(getStepUrl(step), undefined, { shallow: true })
  }

  const addProduct = async (product: ProductProps, channel: string) => {
    const addons = [
      voice,
      tv,
      wier,
      vas,
    ]

    if (addons.some(e => e?.productOfferingId === product?.productOfferingId)) {
      const productOptions = await removeFromCart({
        product,
        client,
        cartInstanceIdentifier,
        channel,
        options,
      })

      return setOptions({
        ...options,
        ...productOptions,
      })
    }

    const productOptions = await addToCartOptions({
      product,
      options,
      client,
      channel,
    })

    return setOptions(productOptions)
  }

  return (
    <Box>
      <Box py={6}>
        <Grid container className={classes.container} justify="space-between">
          <Grid item xs={12}>
            {appliedReferralCode && appliedProductId &&
              <Box mb={2} className={cx(classes.infoBanner, classes.infoBannerPrimary)}>
                <GiftIcon/>
                <Typography variant="body2">
                  Your referral code has been applied. Complete your order to receive your Amazon voucher.
                </Typography>
              </Box>}
            <Hidden smUp>
              <Grid item xs={12}>
                <Box mb={3}>
                  <OrderButton id="SummarySection_OrderButton-mobile"/>
                </Box>
              </Grid>
            </Hidden>
            {countDownTimerStart && <CountdownBanner countDownTimerStart={countDownTimerStart}/>}
          </Grid>
          <Grid item xs={12} md={6} className={classes.detailContainer}>
            <Box mb={2}>
              <Typography variant="h4">Address</Typography>
            </Box>
            {data.address && formatInstallationAddress(data.address)
              .split(',')
              .map(line => <Typography key={line} variant="body1">{line}</Typography>)}
          </Grid>
          <Grid item xs={12} md={6} className={classes.detailContainer}>
            <PersonalDetails data={data} switchStep={switchStep}/>
          </Grid>
          <Grid item xs={12} md={6} className={classes.detailContainer}>
            <InstallationDetails timeslot={timeslot} switchStep={switchStep} dcms={dcmsEligibility}/>
          </Grid>
          {(!isCommunity && paymentDetails) &&
          <Grid item xs={12} md={6} className={classes.detailContainer}>
            <PaymentDetails paymentDetails={paymentDetails} switchStep={switchStep}/>
          </Grid>}
          <Grid item container xs={12}>
            {summaryData.components.map(({
              type, ...rest
            }, i) => {
              const C: any = getButterComponentByType(type)
              if (type === 'products') {
                return (
                  <Box className={classes.hidenProducts}>
                    <C
                      key={i}
                      {...rest}
                      on_click_package={(product: ProductProps, channel: string) => addProduct(product, channel)}
                    />
                  </Box>

                )
              }

              return null
            })}
          </Grid>
          <Grid item container xs={12} className={classes.productsContainer}>
            {(broadband || bundle) && <ProductDetails broadband={broadband} bundle={bundle} voice={voice} tv={tv} wier={wier} vas={vas} discount={discount} promo={promo}/>}
          </Grid>
          {oneTouchSwitching &&
          <Grid item container xs={12}>
            <OTSDetails/>
          </Grid>}
          <Hidden smDown>
            <Grid item xs={12}>
              <Box pt={3}>
                <OrderButton id="SummarySection_OrderButton-desktop"/>
              </Box>
            </Grid>
          </Hidden>
          {voice &&
            <Grid item container xs={12} className={classes.vulnerableMention}>
              <Typography variant="body2">
                In the event of a power cut or a broadband failure, you need to have an alternative such as a mobile phone to call the emergency services, as your Home Phone will not work. Calls to UK Emergency Services are unavailable in the event that you do not have access to your Broadband
              </Typography>
            </Grid>}
          {placeOrderError && <OrderError error={placeOrderError}/>}
        </Grid>
      </Box>
    </Box>
  )
}

export default SummarySection
