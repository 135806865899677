import React, {
  useEffect,
  useState,
} from 'react'
import { css } from 'linaria'
import {
  Container, Grid, Link,
  useMediaQuery,
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import LayoutItems from '../LayoutItems/LayoutItems'
import { PostListResponse } from '../../../utils/blog'
import theme from '../../../styles/theme'
import butter from '../../../butter'
import fontSizes from '../../../styles/fontSizes'

export interface ItemOverviewProps {
  readonly item_data: PostListResponse;
  readonly link_text?: string;
  type_items: string;
  page_size_items: number;
}

const classes = {
  container: css`
    padding: ${theme.spacing(6, 0)};
    .MuiGrid-item {
      padding: ${theme.spacing(2)}px;
      display: flex;
      justify-content: center;
    }
  `,
  link: css`
    display: block !important;
    font-size: ${fontSizes.body1};
    font-weight: ${theme.typography.fontWeightBold!};
    margin: ${theme.spacing(1)}px auto !important;
  `,
}

export default function ItemOverview({
  item_data,
  link_text,
  type_items,
  page_size_items,
}: ItemOverviewProps) {
  const [
    postData,
    setPostData,
  ] = useState(item_data)

  const [
    page,
    setPage,
  ] = useState(1)

  useEffect(() => {
    setPostData(item_data)
    setPage(1)
  }, [item_data])

  const {
    data, meta,
  } = postData

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const scrollToFirstArticle = () => {
    const element = document.querySelector('#blog-articles')
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  if (data.length === 0) {
    return null
  }

  const categoryToFetch = (input: string) => {
    return input.replace(/\s+/g, '-')
      .toLowerCase()
  }

  async function fetchPage(pageNumber: number) {
    const response = await butter.post.list({
      page: pageNumber,
      page_size: page_size_items,
      exclude_body: true,
      category_slug: categoryToFetch(type_items),
    })
    setPostData(response.data)
    setPage(pageNumber)
  }

  const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    fetchPage(value)
    scrollToFirstArticle()
  }

  const totalPages = Math.ceil(meta.count / page_size_items)

  return (
    <Container classes={{ root: classes.container }} id="blog-articles">
      <Grid container direction="column">
        <Grid container>
          <LayoutItems post_items={data} type_items={type_items}/>
        </Grid>
        {totalPages > 1 && (
          <Grid item xs>
            <Pagination
              count={totalPages}
              siblingCount={isMobile ? 0 : 1}
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
          </Grid>
        )}
        <Link underline="always" color="secondary" className={classes.link} href="/community-connect/blog/all-articles">{link_text}</Link>
      </Grid>
    </Container>
  )
}
