import {
  setISOWeek,
  setISOWeekYear,
  addWeeks,
  getISOWeekYear,
  getISOWeek,
  subWeeks,
  addMonths,
} from 'date-fns'
import { useState } from 'react'

const CALENDAR_MONTHS_WINDOW = 3

export default function useWeekWalker() {
  let d = new Date()

  const minYear = getISOWeekYear(d)
  const minWeek = getISOWeek(d)

  d = addMonths(d, CALENDAR_MONTHS_WINDOW)

  const maxYear = getISOWeekYear(d)
  const maxWeek = getISOWeek(d)

  const [
    year,
    setYear,
  ] = useState(minYear)
  const [
    week,
    setWeek,
  ] = useState(minWeek)
  const [
    weekCount,
    setWeekCount,
  ] = useState(1)

  const isCalendarStart = year === minYear && week === minWeek
  let isCalendarEnd = year === maxYear && week === maxWeek
  const weekLimit = 5

  if (weekCount > weekLimit - 1) {
    isCalendarEnd = true
  }

  return {
    week,
    year,
    isCalendarStart,
    isCalendarEnd,
    weekCount,
    prevWeek() {
      if (isCalendarStart) {
        return
      }

      let date = new Date()

      date = setISOWeek(date, week)
      date = setISOWeekYear(date, year)

      date = subWeeks(date, 1)

      setYear(getISOWeekYear(date))
      setWeek(getISOWeek(date))

      setWeekCount(weekCount - 1)
    },
    nextWeek() {
      if (isCalendarEnd) {
        return
      }

      let date = new Date()

      date = setISOWeek(date, week)
      date = setISOWeekYear(date, year)

      date = addWeeks(date, 1)

      setYear(getISOWeekYear(date))
      setWeek(getISOWeek(date))

      setWeekCount(weekCount + 1)
    },
  }
}
