import { css } from 'linaria'
import theme, {
  COLORS, ROUNDED, fontWeights,
} from '../../styles/theme'
import fontSizes from '../../styles/fontSizes'

const classes = {
  wrapper: css`
    ${theme.breakpoints.up('md')} {
      overflow-x: auto;
    }
  `,
  root: css`
    margin: 0 auto;
    padding-left: ${theme.spacing(4)}px;
    padding-right: ${theme.spacing(4)}px;
    width: fit-content;
    ${theme.breakpoints.down('sm')} {
      padding-left: 5vw;
      padding-right: 5vw;
    }
    img {
      height: ${theme.spacing(5)}px;
      object-fit: contain;
      width: 100%;
      ${theme.breakpoints.down('xs')} {
        height: ${theme.spacing(4)}px;
        width: 80%;
      }
    }
    .MuiTableCell-body {
      color: ${COLORS.bodyTextPrimary};
      background-color: ${COLORS.white} !important;
    }
    ::-webkit-scrollbar {
      color: white;
      padding-bottom: 0px;
    }
    tr:nth-of-type(odd) th:first-child,
    .MuiTableRow-root:not(:last-child, :first-child) {
      background-color: ${COLORS.white} !important;
    }
    .MuiTableRow-root {
      margin: 0px;
      th {
        ${theme.breakpoints.up('md')} {
          text-align: center;
        }
      }
      th .MuiTypography-root p, td {
        ${theme.breakpoints.down('xs')} {
          font-size: 0.75rem;
        }
      }
      :nth-of-type(even) {
        thead, .MuiTableCell-root {
          background-color: ${theme.palette.warning.light} !important;
        }
      }
    }
    .MuiTableCell-root {
      overflow: hidden;
      padding: ${theme.spacing(1, 2)} !important;
      box-sizing: border-box;
      text-align: center;
      border: none;
      border-width: 0;
      font-weight: ${theme.typography.fontWeightLight!};
      ${theme.breakpoints.up('lg')} {
        padding: 0 ${theme.spacing(1)}px;
      }
      p {
        line-height: 1.2;
        margin: 0;
      }
      :first-child {
        p {
          text-align: left;
        }
      }
      :not(:last-child) {
          border-right: solid 1px rgba(82, 70, 88, .16);
        }
      }
  `,
  table: css`
    border-radius: ${ROUNDED.small}px;
    border: 1px solid ${COLORS.gray3};
    margin: 0 auto;
    &.MuiTable-root {
      border-collapse: separate;
      overflow: hidden;
      width: auto;
    }
    ${theme.breakpoints.down('sm')} {
      .MuiTypography-body1, .MuiTypography-body2 {
        &, p {
          font-size: ${fontSizes.caption1};
        }
      }
    }
  `,
  tableCell: css`
    width: 180px;
    min-width: 180px;
    &:first-child {
      width: 200px;
      min-width: 200px;
    }
    .MuiTypography-root {
      font-weight: ${fontWeights.book};
      color:${COLORS.bodyTextPrimary}
    }
    ${theme.breakpoints.down('sm')} {
      &, &:first-child {
        min-width: 30vw;
        width: 30vw;
      }
    }
  `,
  tableCellCompany: css`
    >.MuiBox-root {
      height: ${theme.spacing(5)}px;
    }
  `,
  title: css`
    &.MuiTypography-h3 {
      text-align: center;
      padding-top: ${theme.spacing(4)}px;
      padding-bottom: ${theme.spacing(3)}px;
      color: ${theme.palette.primary.main};
      ${theme.breakpoints.up('md')} {
        padding-top: 0;
        padding-bottom: ${theme.spacing(5)}px;
        font-size: ${fontSizes.h2};
      }
    }
    &.MuiTypography-subtitle2 {
      padding-top: ${theme.spacing(2)}px;
      padding-bottom: ${theme.spacing(4)}px;
      font-weight: ${theme.typography.fontWeightLight!};
      margin-left: ${theme.spacing(8)}px;
      color: ${theme.palette.primary.main};
        ${theme.breakpoints.up('md')} {
          width: 90%;
          text-align: right;
        }
      }
    `,
  emptyHeaderCell: css`
    height: ${theme.spacing(6)}px;
  `,
  logo: css`
    max-width: 90%;
    ${theme.breakpoints.up('md')} {
      max-width: 100%;
    }
  `,
  doneIncon: css`
    svg{
      color:${COLORS.secondary}
    }
  `,
  closeIcon: css`
  svg{
    color:${COLORS.error}
    }
  `,
  primaryColourCell: css`
    .MuiTypography-root {
      color:${COLORS.primary}
      font-weight: ${fontWeights.bold};
    }
  `,
  priceDiferenceColourCell: css`
  .MuiTypography-root {
    color: ${COLORS.error};
    font-weight: ${fontWeights.semibold};
  }
  `,
  secondaryColourCell: css`
    .MuiTypography-root {
      color: ${COLORS.secondary};
      font-weight: ${fontWeights.semibold};
    }
  `,
}
export default classes
